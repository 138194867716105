import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { apiInternalPriceRulePath } from 'routes';
import SearchDropdown from './SearchDropdown';

const priceListInput = document.querySelector('[name*="work_order[price_list_id]"');

function MaterialsServicesSelector({
  workOrderCorrection,
  proposedCorrection: initialProposedCorrection,
  priceRule: initialPriceRule,
  correctionName,
  priceRuleName
}) {
  const [proposedCorrection, setProposedCorrection] = useState(initialProposedCorrection || { id: '', price: 0 });
  const [priceListId, setPriceListId] = useState(priceListInput.value);
  const [priceRule, setPriceRule] = useState(initialPriceRule);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const input = useRef();

  useEffect(() => {
    priceListInput.addEventListener('change', function () {
      setPriceListId(this.value);
    });
  }, []);

  useEffect(() => {
    if (firstTimeRender) {
      setFirstTimeRender(false);
      return;
    }

    if (!priceListId || !proposedCorrection?.id) {
      setPriceRule({});
      return;
    }

    const params = { price_list_id: priceListId };
    if (isWorkOrderCorrectionChanged || !workOrderCorrection.id) {
      params.proposed_correction_id = proposedCorrection?.id;
    } else {
      params.work_order_correction_id = workOrderCorrection.id;
    }

    api.get(apiInternalPriceRulePath(params)).then(({ data }) => setPriceRule(data.price_rule));
  }, [priceListId, proposedCorrection]);

  const handleSelectionClear = () => {
    setProposedCorrection({ id: '', price: 0 });
  };

  const isWorkOrderCorrectionChanged = useMemo(
    () => workOrderCorrection.proposedCorrectionId?.toString() !== proposedCorrection.id?.toString(),
    [proposedCorrection]
  );

  const initPrice = useMemo(
    () => (isWorkOrderCorrectionChanged ? proposedCorrection.price : workOrderCorrection.proposedCorrectionPrice),
    [priceRule]
  );

  const price = useMemo(() => {
    switch (priceRule?.ruleType) {
      case 'fixed':
        return priceRule.value;
      case 'discount':
        return initPrice * (1 - priceRule.value / 100);
      case 'markup':
        return initPrice * (1 + priceRule.value / 100);
      default:
        return initPrice;
    }
  }, [initPrice]);

  useEffect(() => {
    input.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [price]);

  return (
    <>
      <input
        type="hidden"
        ref={input}
        name={correctionName}
        value={proposedCorrection?.id}
        data-price={price}
        data-price-wo-price-list={initPrice}
        data-js-work-order-proposed-correction
      />
      <input type="hidden" name={priceRuleName} value={priceRule?.id ?? ''} />
      {proposedCorrection?.id && (
        <div
          className="qmb-input --x-full"
          style={{ display: 'flex', padding: '8px', boxSizing: 'border-box', alignItems: 'center', width: '24rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <small>{proposedCorrection.type}</small>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '.5rem', alignItems: 'baseline' }}>
              {proposedCorrection.description}
              <button type="button" className="qmb-control" style={{ padding: '3px 6px', borderRadius: '10px' }}>
                {/* <small>{priceRule?.asApplicable ?? 'No Price Rule'}</small> */}
              </button>
            </div>
          </div>
          <button type="button" className="qmb-control--icon" onClick={handleSelectionClear}>
            <i className="fa-light fa-xmark" />
          </button>
        </div>
      )}

      <SearchDropdown proposedCorrection={proposedCorrection} setProposedCorrection={setProposedCorrection} />
    </>
  );
}

MaterialsServicesSelector.propTypes = {
  workOrderCorrection: PropTypes.object.isRequired,
  proposedCorrection: PropTypes.object,
  correctionName: PropTypes.string.isRequired,
  priceRuleName: PropTypes.string.isRequired
};

MaterialsServicesSelector.defaultProps = {
  proposedCorrection: {
    id: '',
    price: 0
  }
};

export default MaterialsServicesSelector;

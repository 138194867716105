import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { listingMapLookupPath, listingLookupPath } from 'routes';
import SearchSelect from '@components/Kendo/SearchSelect';
import I18n, { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';

const namespace = 'features.buildings.price_list_selector';

const accountInput = document.querySelector('[name*="building[account_id]"');

function PriceListSelector({ priceList, isNewRecord, accountsPriceLists, defaultPriceListId, associatedItems }) {
  const [priceListId, setPriceListId] = useState(priceList?.id?.toString() ?? '');
  const input = useRef();
  const [openAsociated, setOpenAsociated] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [foundPriceListId, setFoundPriceListId] = useState('');

  function handleAccountChange() {
    if (this.value === '') {
      setPriceListId(defaultPriceListId);
      return;
    }

    const foundPriceId =
      accountsPriceLists.find((account) => account.id.toString() === this.value)?.price_list_id?.toString() ?? '';

    if (priceListId === '') {
      setPriceListId(foundPriceId);
    } else {
      setOpenAccount(true);
      setFoundPriceListId(foundPriceId);
    }
  }

  useEffect(() => {
    if (!isNewRecord) return;

    accountInput.addEventListener('change', handleAccountChange);

    return () => {
      accountInput.removeEventListener('change', handleAccountChange);
    };
  }, [priceListId]);

  const onChange = (e) => {
    const newId = e.sender.element[0].value;
    let isChanged;
    if (priceListId === newId || newId === '') return;
    setPriceListId((old) => {
      isChanged = newId !== old;
      return newId;
    });

    if (isChanged) {
      input.current.dispatchEvent(new Event('change'));

      if (priceList === null) return;

      if (priceList.id.toString() !== newId && Object.values(associatedItems).some((count) => count > 0))
        setOpenAsociated(true);
    }
  };

  const onCloseAssociatedModal = () => {
    setPriceListId(priceList?.id?.toString());
    setOpenAsociated(false);
  };

  return (
    <>
      <div className="qmb-select">
        <SearchSelect
          name="product"
          value={priceListId}
          dataUrl={listingLookupPath({ type: 'price_list' })}
          valueMapUrl={listingMapLookupPath({ type: 'price_list' })}
          label={I18n.t('features.buildings.price_list_selector.label')}
          placeholder={false}
          selectOptions={{ id: 'price_list', onChange }}
          itemHeight={50}
        />
      </div>
      {openAsociated && (
        <Dialog
          title={translate('dialog_title', { namespace })}
          className="qmb-dialog"
          width={600}
          onClose={onCloseAssociatedModal}>
          <div>
            {translate('note', { namespace, ...associatedItems })}
            <br />
            {translate('subnote', { namespace })}
          </div>
          <br />
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={onCloseAssociatedModal}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setOpenAsociated(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}

      {openAccount && (
        <Dialog
          title={translate('account_title', { namespace })}
          className="qmb-dialog"
          onClose={() => {
            setOpenAccount(false);
          }}>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button
              type="button"
              className="qmb-button"
              onClick={() => {
                setOpenAccount(false);
              }}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setPriceListId(foundPriceListId);
                setOpenAccount(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}
      <input ref={input} type="hidden" name="building[price_list_id]" value={priceListId} id="building_price_list_id" />
    </>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object,
  isNewRecord: PropTypes.bool,
  accountsPriceLists: PropTypes.array.isRequired,
  defaultPriceListId: PropTypes.number.isRequired
};

PriceListSelector.defaultProps = {
  isNewRecord: false
};

export default PriceListSelector;

import React from 'react';
import PropTypes from 'prop-types';
import { priceRulePath } from 'routes';

function ActionButtons({ priceRule, onDelete, notEditable }) {
  return (
    <span>
      {!notEditable && (
        <a className="qmb-control--icon" href={priceRulePath(priceRule.id)}>
          <i className="fa-light fa-pencil" />
        </a>
      )}

      <button
        disabled={notEditable}
        type="button"
        style={{ color: 'red' }}
        className="qmb-control--icon"
        onClick={onDelete}>
        <i className="fa-light fa-trash-can" />
      </button>
    </span>
  );
}

ActionButtons.propTypes = {
  priceRule: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  notEditable: PropTypes.bool.isRequired
};

export default ActionButtons;

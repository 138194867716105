import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { apiInternalPriceRulePath } from 'routes';
import SearchDropdown from './SearchDropdown';

const priceListInput = document.querySelector('[name*="invoice[price_list_id]"]');

function MaterialsServicesSelector({
  invoicingLineItem,
  proposedCorrection: initialProposedCorrection,
  priceRule: initialPriceRule,
  objectName
}) {
  const [proposedCorrection, setProposedCorrection] = useState(initialProposedCorrection || { id: '', price: 0 });
  const [priceListId, setPriceListId] = useState(priceListInput.value);
  const [priceRule, setPriceRule] = useState(initialPriceRule);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const input = useRef();

  useEffect(() => {
    priceListInput.addEventListener('change', function () {
      setPriceListId(this.value);
    });
  }, []);

  useEffect(() => {
    if (firstTimeRender) {
      setFirstTimeRender(false);
      return;
    }

    if (!priceListId || !proposedCorrection?.id) {
      setPriceRule({});
      return;
    }

    const params = { price_list_id: priceListId };
    if (isInvoicingLineItemChanged) {
      params.proposed_correction_id = proposedCorrection?.id;
    } else {
      params.invoicing_line_item_id = invoicingLineItem.id;
    }

    api.get(apiInternalPriceRulePath(params)).then(({ data }) => setPriceRule(data.price_rule));
  }, [priceListId, proposedCorrection]);

  const isInvoicingLineItemChanged = useMemo(
    () => invoicingLineItem.proposedCorrectionId?.toString() !== proposedCorrection.id?.toString(),
    [proposedCorrection]
  );

  const price = useMemo(() => {
    // const initPrice = isInvoicingLineItemChanged ? proposedCorrection.price : invoicingLineItem.unitPrice;
    const initPrice = proposedCorrection.price;

    switch (priceRule?.ruleType) {
      case 'fixed':
        return priceRule.value;
      case 'discount':
        return initPrice * (1 - priceRule.value / 100);
      case 'markup':
        return initPrice * (1 + priceRule.value / 100);
      default:
        return initPrice;
    }
  }, [priceRule]);

  useEffect(() => {
    if (firstTimeRender) return;

    setRelatedFormFields();
    input.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [price]);

  const clearRelatedFormFields = () => {
    document.querySelector(`[name*="${objectName}[qbd_id]"]`).value = '';
    document.querySelector(`[name*="${objectName}[qbd_id]"] + img`)?.remove();

    document.querySelector(`[name*="${objectName}[description]"]`).value = '';
    document.querySelector(`[name*="${objectName}[unit_price]"]`).value = '';
    document.querySelector(`.invoice-is-taxable[name*="${objectName}[is_taxable]"]`).checked = false;
  };

  const setRelatedFormFields = () => {
    clearRelatedFormFields();

    document.querySelector(`[name*="${objectName}[description]"]`).value = proposedCorrection.description;
    document.querySelector(`[name*="${objectName}[unit_price]"]`).value = Math.round(price * 100) / 100;
    document.querySelector(`.invoice-is-taxable[name*="${objectName}[is_taxable]"]`).checked =
      proposedCorrection.taxable;

    if (proposedCorrection.qboId) {
      document.querySelector(`[name*="${objectName}[qbo_id]"]`).value = proposedCorrection.qboId;

      const img = document.createElement('img');
      img.height = 16;
      img.width = 16;
      img.src = '/qbo.svg';
      img.className = 'custom-icon--qbo mt-2';
      img.title = 'QuickBooks';
      document.querySelector(`[name*="${objectName}[qbo_id]"]`).after(img);
    } else if (proposedCorrection.qbdId) {
      document.querySelector(`[name*="${objectName}[qbd_id]"]`).value = proposedCorrection.qbdId;
      const img = document.createElement('img');
      img.height = 16;
      img.width = 16;
      img.src = '/qbo.svg';
      img.className = 'custom-icon--qbo mt-2';
      img.title = 'QuickBooks';
      document.querySelector(`[name*="${objectName}[qbd_id]"]`).after(img);
    }

    update_total();
  };

  const handleSelectionClear = () => {
    setProposedCorrection({ id: '', price: 0 });

    clearRelatedFormFields();

    update_total();
  };

  return (
    <>
      <input type="hidden" ref={input} name={`${objectName}[proposed_correction_id]`} value={proposedCorrection?.id} />
      <input type="hidden" ref={input} name={`${objectName}[product]`} value={proposedCorrection?.description} />
      <input type="hidden" name={`${objectName}[price_rule_id]`} value={priceRule?.id ?? ''} />
      {proposedCorrection?.id && (
        <div
          className="qmb-input --x-full"
          style={{ display: 'flex', padding: '8px', boxSizing: 'border-box', alignItems: 'center', width: '24rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <small>{proposedCorrection.type}</small>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '.5rem', alignItems: 'baseline' }}>
              {proposedCorrection.description}
              <button type="button" className="qmb-control" style={{ padding: '3px 6px', borderRadius: '10px' }}>
                {/* <small>{priceRule?.asApplicable ?? 'No Price Rule'}</small> */}
              </button>
            </div>
          </div>
          <button type="button" className="qmb-control--icon" onClick={handleSelectionClear}>
            <i className="fa-light fa-xmark" />
          </button>
        </div>
      )}

      <SearchDropdown proposedCorrection={proposedCorrection} setProposedCorrection={setProposedCorrection} />
    </>
  );
}

MaterialsServicesSelector.propTypes = {
  invoicingLineItem: PropTypes.object.isRequired,
  proposedCorrection: PropTypes.object,
  objectName: PropTypes.string.isRequired,
  priceRule: PropTypes.object.isRequired
};

MaterialsServicesSelector.defaultProps = {
  proposedCorrection: {
    id: '',
    price: 0
  }
};

export default MaterialsServicesSelector;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { listingMapLookupPath, listingLookupPath } from 'routes';
import SearchSelect from '@components/Kendo/SearchSelect';
import { translate } from '@utils/i18n';

const namespace = 'features.proposals.price_list_selector';

function PriceListSelector({ priceList }) {
  const [priceListId, setPriceListId] = useState(priceList?.id?.toString());
  const input = useRef();
  const onChange = (e) => {
    const newId = e.sender.element[0].value;
    let isChanged;
    if (priceListId === newId) return;
    setPriceListId((old) => {
      isChanged = newId !== old;
      return newId;
    });
    if (isChanged) input.current.dispatchEvent(new Event('change'));
  };

  return (
    <div className="qmb-select" style={{ height: '43px' }}>
      <SearchSelect
        name="product"
        value={priceListId}
        dataUrl={listingLookupPath({ type: 'price_list' })}
        valueMapUrl={listingMapLookupPath({ type: 'price_list' })}
        label={translate('price_list', { namespace })}
        placeholder={false}
        selectOptions={{ id: 'price_list', onChange }}
        itemHeight={50}
      />
      <input ref={input} type="hidden" name="invoice[price_list_id]" value={priceListId} />
    </div>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object
};

export default PriceListSelector;
